// Sprite :: Structure

.sprite {
	display: inline-block;
	background-image: url("../images/icons/sprite.png");
	background-repeat: no-repeat;
	vertical-align: text-top;
	background-size: 550px;
}

.sprite {
	&.back-top {
		width: 40px;
		height: 40px;
	}

	&.back-top {
		background-position: -470px 0;
	}

	&.snapshat-small,
	&.facebook-small,
	&.instagram-small {
		width: 25px;
		height: 25px;
		transition: all .1s ease-in-out;
		&:hover {
			transform: translateY(-2px);
		}
	}
	&.snapshat-large,
	&.facebook-large,
	&.instagram-large {
		width: 40px;
		height: 40px;
		transition: all .1s ease-in-out;
		&:hover {
			transform: translateY(-2px);
		}
	}

	&.snapshat-small {
		background-position: -25px 0;
	}
	&.facebook-small {
		background-position: -50px 0;
	}
	&.instagram-small {
		background-position: -75px 0;
	}

	&.snapshat-large {
		background-position: -20px -25px;
	}
	&.facebook-large {
		background-position: -60px -25px;
	}
	&.instagram-large {
		background-position: -100px -25px;
	}

	&.search-icon {
		width: 20px;
		height: 20px;
		background-position: -100px 0;
	}
	&.arrow-dropdown {
		width: 15px;
		height: 11px;
		background-position: -120px 0;
	}

	&.arrow-carousel-left-ye-a,
	&.arrow-carousel-left-ye-b {
		width: 35px;
		height: 35px;
		transition: all .2s ease-in-out;
		&:hover {
			transform: scale(.9);
		}
	}

	&.arrow-carousel-right-ye-a,
	&.arrow-carousel-right-ye-b {
		width: 35px;
		height: 35px;
		transition: all .2s ease-in-out;
		&:hover {
			transform: scale(.9);
		}
	}

	&.arrow-carousel-left-ye-a {
		background-position: -140px 0;
	}

	&.arrow-carousel-left-ye-b {
		background-position: -140px -35px;
	}

	&.arrow-carousel-right-ye-a {
		background-position: -175px 0;
	}

	&.arrow-carousel-right-ye-b {
		background-position: -175px -35px;
	}

	&.icon-skills-1,
	&.icon-skills-2,
	&.icon-skills-3 {
		width: 65px;
		height: 65px;
	}

	&.icon-skills-1 {
		background-position: -215px 0;
	}
	&.icon-skills-2 {
		background-position: -280px 0;
	}
	&.icon-skills-3 {
		background-position: -345px 0;
	}

	&.action-account,
	&.action-basket {
		width: 30px;
		height: 30px;
		transition: all .1s ease-in-out;
		&:hover {
			transform: translateY(-2px);
		}
	}

	&.action-account {
		background-position: -410px 0;
	}
	&.action-basket {
		background-position: -440px 0;
	}
}
