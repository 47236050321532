// Custom variables :: Colors
//
// -> bk : black;
// -> gy : grey;
// -> wh : white;
// -> pu : purple;
// -> bl : blue;
// -> br : brown;
// -> or : orange;
// -> gn : green;
// -> pk : pink;
$wh-a: #FFFFFF;
$bk-a: #000000;
$bk-b: #17171B;
$ye-a: #C59842;
$ye-b: #FFC961;
$gy-a: #F5F5F5;
$gy-b: #aeaeae;

// Color
.c-wh-a {
  color: $wh-a;
}
.c-bk-a {
  color: $bk-a;
}
.c-bk-b{
  color: $bk-b;
}
.c-ye-a {
  color: $ye-a;
}
.c-ye-b {
  color: $ye-b;
}
.c-gy-a {
  color: $gy-a;
}
.c-gy-a {
  color: $gy-a;
}
.c-gy-b {
  color: $gy-b;
}

// Background
.bg-bk-a {
  background-color: $bk-a;
}
.bg-bk-b{
  background-color: $bk-b;
}

.bg-wh-a {
  background-color: $wh-a;
}
.bg-ye-a {
  background-color: $ye-a;
}
.bg-ye-b {
  background-color: $ye-b;
}
.bg-gy-a {
  background-color: $gy-a;
}
.bg-gy-b {
  background-color: $gy-b;
}
