// Font family
$font-stack: 'Inter', sans-serif;
// Html

html {
  color: $bk-a;
  font-size: #{$font-size}px !important;
}

body {
  font-family: $font-stack !important;
}
// Google font ballback

// Title :: soft reset

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin-bottom: 0;
  line-height: 1.2em;
}

p {
  margin-bottom: 0;
}

.h-like-a {
  font-size: 46px;
  font-weight: 100;
  line-height: 1em;
}
.h-like-b {
  font-size: 32px;
  font-weight: 200;
}
.h-like-c {
  font-size: 26px;
  font-weight: 100;
}
.h-like-d {
  font-size: 20px;
  font-weight: 200;
}

@include media-breakpoint-down(lg) {
  .h-like-a {}
}
@include media-breakpoint-down(md) {
  .h-like-a {}
}
@include media-breakpoint-down(sm) {
  .h-like-a {
    font-size: 40px;
  }
  .h-like-b {
    font-size: 28px;
  }
  .h-like-c {
    font-size: 23px;
  }
}
@include media-breakpoint-down(xs) {
  .h-like-a {}
}
// Paragraph hierarchy :: Bigger to smaller

p {
  margin-bottom: 0;
}
.p-like-a {
  font-size: 12px;
  font-weight: 100;
  line-height: 1.8em;
}
@include media-breakpoint-down(lg) {
  .p-like-a {}
}
@include media-breakpoint-down(md) {

}
@include media-breakpoint-down(sm) {

}
@include media-breakpoint-down(xs) {
  .p-like-a {
    font-size: 12px;
  }

}
