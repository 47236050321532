// Shopping card
.order-aside {
  .selectpicker-wp .dropdown-toggle {
    background-color: $wh-a;
    border: 1px solid $gy-a;
    padding-left: 10px !important;

    .filter-option-inner {
      padding: 0 !important;
    }
  }

  .title-main {
    background-color: shade($gy-a, 5%);
    color: $bk-a;
    font-size: px(12);
    padding: px(17) px(15) px(16);
    border-right: 1px solid $gy-a;
    text-transform: uppercase;
    font-weight: 400;
    border-bottom: none !important;
  }

  .content {
    border: 1px solid $gy-a;
    border-bottom: none;
  }

  .content .item {
    position: relative;
    display: flex;
    align-items: center;
    padding: px(13) px(15) px(12);
    border-bottom: 1px solid $gy-a;

    &.except {
      background-color: tint($gy-a, 60%);
    }

    .title {
      max-width: 100%;
      width: 100%;
      padding-right: 10px;
      font-size: px(12);
      margin-bottom: 0;

      strong {
        font-size: 9px;
        color: $bk-b;
        background-color: tint($gy-b, 50%);
        margin-left: 3px;
        white-space: nowrap;
        text-align: center;
        line-height: 0;
        padding: 4px 4px 2px;
      }
    }

    &.total {
      padding: px(17) px(15) px(16);
      background-color: $bk-a;
      color: $wh-a;

      .title {
        text-transform: uppercase;
      }

      .price {}
    }

    .price {
      font-size: px(13);
      white-space: nowrap;
    }
  }
}

#slick-product-wp {
  position: relative;

  .label-promo {
    background-color: $ye-b;
    color: $wh-a;
    position: absolute;
    top: 0;
    left: 0;
    padding: px(4) px(7);
    font-size: px(12);
    z-index: 1;
  }

  img {
    max-width: 100%;
  }

  .zoom-wp {
    img {
      max-width: 100%;
      width: 600px;
    }
  }
}

#article-page {
  padding-top: px(50);

  .order-wp {
    display: block;
    padding: px(30);
    background-color: $gy-a;
  }


  .toggle-wp {
    margin-top: px(20);

    &.open {
      .title .material-icons {
        transform: rotate(180deg);
      }
    }

    .title {
      text-align: left;
      display: block;
      cursor: pointer;
      padding-bottom: px(2);
      text-transform: uppercase;
      font-weight: 100;
      color: $bk-b;
      font-size: px(15);
      border-bottom: 1px solid tint($gy-b, 50%);
      width: 100%;
      display: block;
      position: relative;



      h3 {
        margin-bottom: 0;
        font-size: px(14);
        font-weight: 700;
        text-transform: uppercase;
      }

      .material-icons {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        font-size: px(18);
      }
    }

    .toggle-content {
      padding-top: px(15);
      line-height: 1.25em;
      font-size: px(14);

      .content-txt {
        position: relative;
        display: block;
        margin-bottom: px(5);
        line-height: 1.4em;
        color: $gy-b;
      }
    }
  }

  .price-wp.promo-on {
    .price:first-child {
      font-size: px(13);
      text-decoration: line-through;
    }

    .price:last-child {
      margin-top: px(4);
      color: $ye-b;
    }
  }

  .list {
    list-style: disc;
    margin-left: 15px;

    .selectpicker-wp {
      display: inline-block;
      width: 200px;

      .dropdown-toggle {
        height: auto !important;
        padding: 10px 30px 10px 10px !important;
        background-color: $gy-a;

        .filter-option-inner-inner {
          overflow: hidden;
          display: block;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }

    li {
      font-weight: 100;
      margin-bottom: 5px;
      font-size: 14px;

      .label-availablity {
        position: relative;
        color: $wh-a;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 9px;
        padding: px(5) px(10);
        line-height: 1em;
        vertical-align: middle;
        display: inline-block;
        padding-left: px(19);

        .material-icons {
          font-size: px(10);
          position: absolute;
          top: 50%;
          left: px(6);
          margin-top: px(-5);
        }

        &.ok {
          color: #155724;
          background-color: #d4edda;
        }

        &.ko {
          color: #721c24;
          background-color: #f8d7da;
        }

        &.warning {
          color: #856404;
          background-color: #fff3cd;
        }
      }



      strong {
        font-weight: 400;
      }
    }
  }

  .price-wp {
    padding: 0 px(15);
    font-size: 18px;
    font-weight: 400;
    left: 0;
    text-align: center;

    >* {
      margin: 0;
      line-height: 1em;
      vertical-align: middle;
      display: block;
    }
  }

  .btn-a {
    display: block;
    position: relative;
    text-align: center;
    width: 100%;
    padding-right: px(8);
    line-height: 1.2em;
    padding-left: px(8);
    font-size: px(12);
    padding-left: px(35);

    .material-icons {
      position: absolute;
      top: 50%;
      font-size: px(20);
      left: px(15);
      margin-top: px(-10);
    }

    @include media-breakpoint-down(md) {
      padding-left: px(8);

      .material-icons {
        display: none;
      }
    }

    @include media-breakpoint-down(sm) {
      padding-left: px(35);

      .material-icons {
        display: block;
      }
    }
  }

  .selectpicker-wp .dropdown-toggle {
    background-color: $wh-a;
    border: none;
    height: 40px;
    padding: 12px 10px 10px !important;
    list-style: 1em !important;
    display: block;

    .filter-option-inner {
      line-height: 1em !important;
      padding: 0 !important;
    }
  }

  .title-brand {
    text-transform: uppercase;
    font-weight: 400;
    font-size: px(13);
    color: $gy-b;
    margin-bottom: 0;
  }

  .title-product {
    font-weight: 100;
    margin-bottom: px(10);
    margin-top: px(3);
    color: $bk-b;
    line-height: 1.1em;
    font-size: 30px;
  }

  .desc {
    line-height: 1.4em;
    color: $bk-b;
    font-weight: 100;
    font-size: px(15);
  }

  .content {
    margin: px(25) 0 px(40);
  }

  @include media-breakpoint-down(md) {
    .order-wp {
      padding: px(20);
    }
  }

  @include media-breakpoint-down(sm) {
    .order-wp {
      padding: px(30);
    }
  }

  @include media-breakpoint-down(sm) {
    padding-top: px(30);
  }
}


.item-more {
  padding: px(40) px(50);
  margin-top: px(30);

  p {
    font-size: px(14);
    line-height: 1.5em;
    color: $gy-b;
    margin-top: px(15);
    margin-bottom: 0;

    strong {
      color: $ye-b;
    }
  }

  img {
    margin-bottom: px(15);
  }

  ul {
    margin-top: px(15);
  }

  ul li {
    position: relative;
    font-size: px(14);
    line-height: 1.3em;
    padding-left: px(15);

    &:before {
      content: "";
      position: absolute;
      top: px(7);
      left: 0;
      width: px(5);
      height: px(5);
      background-color: $gy-b;
      border-radius: 100%;
      display: inline-block;
    }
  }

  @include media-breakpoint-down(sm) {
    margin-top: px(15);
    padding: px(20) px(30);
  }
}



#slick-product-nav {
  margin-top: 30px;

  button {
    border: 3px solid $gy-a;
    padding: 5px;
    transition: all 0.2s ease-in-out;

    &:hover,
    &:focus {
      opacity: .5;
    }
  }
}

.filter-wp {
  position: relative;

  .btn-filter {
    position: absolute;
    top: 7.5px;
    right: 0;
    display: none;
    font-size: 14px;
    padding: 7.5px 12.5px;

    @include media-breakpoint-down(md) {
      display: block;
    }
  }

  .title {
    font-weight: 300;
    font-size: 24px;
  }

  .content {
    padding: 10px;
    border-top: 1px solid tint($gy-b, 50%);
  }

  .item {
    background-color: tint($gy-a, 60%);
    color: $bk-b;
    margin-top: 20px;

    @include media-breakpoint-down(md) {
      display: none;
    }

    @include media-breakpoint-down(lg) {
      margin-top: 10px;
    }
  }

  .title-sub {
    font-weight: 300;
    font-size: 16px;
  }

  .btn-wp {
    background-color: $gy-a;
    display: flex;
    justify-content: space-between;
    padding: 7px 15px;
    align-items: center;
    text-align: left;
    width: 100%;
    text-transform: uppercase;
    font-size: 12px;

    &.open {
      .material-icons {
        transform: rotate(180deg);
      }
    }
  }
}