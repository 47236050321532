// Reset :: Button & Links

button {
	background-color: transparent;
	border: none;
	padding: 0;
	outline: none !important;
	cursor: pointer;
}

a,
a:focus,
a:hover {
	text-decoration: none;
	cursor: pointer;
	color: inherit;
}
// Hover :: Link

.td-und-hover-a:focus,
.td-und-hover-a:hover {
	text-decoration: underline;
}

.td-und-hover-b:focus > *,
.td-und-hover-b:hover > * {
	text-decoration: underline;
}
// Buttons :: List

.btn-a {
	&.yellow,
	&.grey {
		background-color: $ye-a;
		padding: 10px 15px;
		color: $wh-a;
		display: inline-block;
		font-size: 16px;
		text-align: center;
		transition: all 0.2s ease-in-out;
		> * {
			display: inline-block;
			vertical-align: middle;
		}
		.material-icons {
			font-size: 18px;
			margin-left: 5px;
		}
	}
	&.yellow {
		background-color: $ye-a;
		color: $wh-a;
		&:hover {
			background-color: tint($ye-a, 20%);
		}
	}
	&.grey {
		background-color: shade($gy-a, 10%);
		color: shade($gy-b, 40%);
		&:hover {
			background-color: shade($gy-a, 5%);
		}
	}
	&.type-underline {
		color: $ye-a;
		display: inline-block;
		font-weight: 100;
		font-size: 18px;
		position: relative;
		&:after {
			content: "";
			height: 1px;
			background-color: $ye-a;
			position: absolute;
			bottom: 0px;
			left: 0;
			width: 100%;
			transition: all 0.2s ease-in-out;
		}
		&:hover {
			&:after {
				width: 20%;
			}
		}
	}
}
// Button :: Hamburger

.btn-mobile-hamburger {
	display: block;
	margin: 0 auto;
	width: 30px;
	height: 20px;
	position: relative;
	z-index: 2;
	transform: rotate(0deg);
	cursor: pointer;
	transition: all 0.2s ease-in-out;

	span {
		display: block;
		position: absolute;
		height: 1px;
		width: 100%;
		background: $bk-a;
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: all 0.2s ease-in-out;
	}

	span:nth-child(1) {
		top: 0;
	}

	span:nth-child(2),
	span:nth-child(3) {
		top: 9px;
	}

	span:nth-child(4) {
		top: 18px;
	}
}
// Button :: Hamburger Open

.menu-open .btn-mobile-hamburger {
	span:nth-child(1) {
		top: 18px;
		width: 0;
		left: 50%;
	}

	span:nth-child(2) {
		transform: rotate(45deg);
	}

	span:nth-child(3) {
		transform: rotate(-45deg);
	}

	span:nth-child(4) {
		top: 18px;
		width: 0;
		left: 50%;
	}
}
// Button :: Top

#btn-back-top {
	@include position(fixed, null 30px 30px null);
	z-index: 99;
	transition: all 0.2s ease-in-out;

	&:focus,
	&:hover {}
	@include media-breakpoint-down(sm) {
		right: 15px;
		bottom: 15px;
	}
}


 .pagination {
 	justify-content: center;
 	margin-top: 0;

 	.page-link {
		color: tint($bk-a, 40%);
 		height: 35px;
 	}

 	.page-link.active {
 		background-color: $ye-a !important;
 		color: $wh-a !important;
 		border-top-color: $ye-a;
 		border-bottom-color: $ye-a;
 		font-weight: 400;
 	}

 	.page-item.link-arrow .page-link {
 		padding: px(6) px(12);
 		background-color: tint($gy-a, 30%);
 		color: tint($bk-a, 40%);
 		transition: all 0.2s ease-in-out;
 	}

 	.page-item.link-arrow .page-link:focus,
 	.page-item.link-arrow .page-link:hover {
 		background-color: $ye-a;
 		color: $wh-a;
 	}

 	.page-item .page-link:focus,
 	.page-item .page-link:hover {
 		background-color: $gy-a;
 	}
 }
