// // Header :: Fixed or small/large
// body {
// 	&.header-fixed {
// 		padding-top: 110px;
// 		@include media-breakpoint-down(lg) {
// 			padding-top: 52px;
// 		}
// 		@include media-breakpoint-down(md) {
// 			padding-top: 55px;
// 		}
// 		@include media-breakpoint-down(sm) {
// 			padding-top: 67px;
// 		}
// 		@include media-breakpoint-down(xs) {
// 			padding-top: 45px;
// 		}
// 	}
// }


body.header-small #header {}

@include media-breakpoint-up(lg) {
  body.header-small #header {}
}


body.header-fixed {
  #header {
    position: fixed;
    top: 0;
    margin-top: -82px;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, .05);

    @include media-breakpoint-down(lg) {
      margin-top: -80px;
    }

    @include media-breakpoint-down(md) {
      margin-top: -93px;
    }
  }

}


// Navigation :: Force Display on large device
@include media-breakpoint-up(md) {
  #nav-main {
    display: flex !important;
  }

  #nav-mobile-trigger {
    display: none;
  }
}

// Header :: Structure

.menu-open {
  #header .search-wp {
    width: calc(90vw);
    background-color: $gy-a;
    position: absolute;
    top: 40px;
    right: 10px;
    display: block !important;
    margin-right: 0;
    input {
      background-color: $gy-a;
      border-bottom: 0;
    }

    button {
      top: 3px;
      right: 6px;
    }
  }
}

#nav-mobile-trigger {
  position: absolute;
  top: 27.5px;
  right: 15px;
  z-index: 100;
/*
  @include media-breakpoint-down(xs) {
    left: auto;
    right: 15px;
  }*/
}

#header {
  top: 0;
  width: 100%;
  position: relative;
  z-index: 100;
  width: 100%;
  padding: 0;
  background-color: $wh-a;

  .header-top {
    padding: 5px 0 5px;

    @include media-breakpoint-down(lg) {
      /*padding: 40px 0 20px;*/
    }

    @include media-breakpoint-down(md) {
      padding-bottom: 10px;
    }

    @include media-breakpoint-down(sm) {
      /*padding: 20px 0 20px;*/
      .logo {
        width: 120px;
        display: block;
        /*top: 5px;*/
        position: relative;
      }
    }

    @include media-breakpoint-down(xs) {

    }


  }

  .search-wp {
    display: inline-block;
    position: relative;
    width: 275px;
    float: right;
    margin-right: 120px;

    input {
      border: 0;
      border-bottom: 1px solid $gy-b;
      outline: 0;
      color: $gy-b;
      padding: 3px 30px 3px 10px;
      font-weight: 100;
      font-style: italic;
      width: 100%;
      -webkit-appearance: none;
      transition: all .2s ease-in-out;

      &:focus {
        border-color: tint($bk-a, 30%);
      }
    }

    button {
      position: absolute;
      top: 0;
      right: 0;
    }

    @include media-breakpoint-down(lg) {
      width: 225px;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .social-wp {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .actions-wp {
    position: absolute;
    top: 0;
    right: 20px;

    li {
      position: relative;

      .counter {
        background-color: $ye-a;
        color: $wh-a;
        display: inline-block;
        min-width: 16px;
        text-align: center;
        font-size: 10px;
        font-weight: 100;
        position: absolute;
        top: -12px;
        right: -12px;
        height: 16px;
        border-radius: 100%;
      }
    }

    @include media-breakpoint-down(sm) {
      position: absolute;
      top: -15px;
      right: 60px;
    }
  }

  .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
  }

  .header-main {
    padding: 10px 0 30px;

    @include media-breakpoint-down(lg) {
      padding: 15px 0 20px;
    }

    @include media-breakpoint-down(md) {
      padding: 10px 0;

      .container {
        border-top: 1px solid tint($gy-b, 20%);
        padding-top: 10px;
        max-width: 100%;
        padding-right: 0;
      }
    }

    @include media-breakpoint-down(sm) {
      display: none;
      text-align: left;
      padding: 0;
      border-bottom: 0;

      #nav-main {
        display: block !important;
        padding: 50px 0 15px 0;

        .item {
          display: block !important;
          padding: 10px 0 0;

          .nav-sub {
            background-color: $gy-a;
            padding: 15px;
            margin: 10px 0;
          }
        }

        .link-sub {
          font-size: 14px;

          &:before {
            top: -1px;
          }
        }

        .link {
          display: block;
          height: auto;
          font-size: 18px;
          font-weight: 100;
          text-align: left;
          padding-left: 15px;

          &.active {
            color: $ye-a;
          }

          &:after {
            display: none;
          }
        }
      }

    }
  }
}

// Navigation :: Main

#nav-main {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @include media-breakpoint-down(md) {
    padding-left: 15px;
  }

  .item {
    flex-grow: 1;
    position: relative;

    &[data-navsub] {
      .link {
        text-align: center;
        position: relative;

        &:after {
          content: "";
          position: absolute;
          top: 4px;
          right: -20px;
          @extend .sprite;
          @extend .arrow-dropdown;
        }
      }
    }
  }

  .link {
    font-weight: 100;
    font-size: 15px;

    @include media-breakpoint-down(md) {
      font-size: 13px;
    }
  }

  .link:focus,
  .link:hover {
    color: $ye-a;

    &:after {
      content: "";
      background-position: -120px -10px !important;
    }
  }

  .link.active {
    color: $ye-a;

    &:after {
      opacity: 1;
    }
  }

  .nav-sub {
    .item-sub {}

    .link-sub {
      font-size: 13px;
      font-weight: 100;
      padding-left: 14px;
      position: relative;

      @include media-breakpoint-down(md) {
        font-size: 12px;
      }

      &:before {
        content: '•';
        position: absolute;
        top: -2px;
        left: 0;
        font-size: 10px;

      }
    }

    .link-sub:focus,
    .link-sub:hover {
      color: $ye-a;
    }

    .link-sub.active {
      color: $ye-a;
    }
  }

}

// Navigation :: Sub

.nav-sub {
  display: none;
  position: relative;
  top: auto;
  left: auto;
  height: auto;
  opacity: 1;
  background-color: $wh-a;
}

li[data-navsub].focus>.nav-sub {
  top: 52px;

  @include media-breakpoint-down(lg) {
    top: 43px;
  }

  @include media-breakpoint-down(lg) {
    top: 31px;
  }
}

li[data-navsub] {
  position: relative;
}

@include media-breakpoint-up(md) {
  li[data-navsub].focus>.nav-sub {
    visibility: visible;
    display: block;
    padding: 0 20px 15px 20px;
    height: auto;
    opacity: 1;
  }

  .nav-sub {
    display: none;
    position: absolute;
    left: 0;
    z-index: 99;
    overflow: hidden;
    height: 0;
    width: 200px;
    padding: 0;
    opacity: 0;
    background-color: $wh-a;
    transition: all 0.1 ease-in-out;
  }
}


#header-page {
  background-color: $bk-b;
  background-image: url("../images/illustrations/bg-inner.jpg") !important;
  background-position: center bottom;
  background-size: cover;
  padding: 65px 0;
  color: $wh-a;
  text-align: center;

  .title {
    font-weight: 100;
    font-size: 42px;
    text-shadow: 0px 13px 21px rgba(0, 0, 0, .75);
  }

  .desc {
    margin-top: 15px;
    font-weight: 100;
    font-size: 14px;
    line-height: 1.5em;
  }

  @include media-breakpoint-down(lg) {
    padding: 50px 0;

    .title {
      font-size: 36px;
    }
  }

  @include media-breakpoint-down(md) {
    .title {
      font-size: 32px;
    }

    .desc {
      margin-top: 10px;
    }
  }

  @include media-breakpoint-down(sm) {
    padding: 30px 0;

    .title {
      font-size: 30px;
    }

    .desc {
      font-size: 12px;
    }
  }
}

.bs-tooltip-bottom {
  margin-top: 5px;
}
