#footer {
	color: $bk-a;
	.link-phone {
		font-weight: 100;
		font-size: 17px;
		display: block;
	}
	.list-link {
		li {
			display: block;
			.link {
				display: inline-block;
				position: relative;
				padding: 5px 0 0 15px;
				font-weight: 100;
				&:before {
					content: "•";
					font-size: 10px;
					position: absolute;
					top: 8px;
					left: 0;
				}
			}
		}
	}

	.skills-item {
		display: flex;
		align-items: center;
		.txt {
			font-size: 14px;
			font-weight: 100;
			line-height: 1.4em;
			em {
				display: block;
			}
		}
		@include media-breakpoint-down(md) {
			display: block;
			text-align: center;
			.txt {
				margin-top: 10px;
			}
		}
		@include media-breakpoint-down(xs) {
			text-align: left;
			display: flex;
		}
	}
	.link {
		&:hover {
			text-decoration: underline;
		}
	}
	.social {
		display: flex;
		align-items: center;
		line-height: 1.1em;
		justify-content: flex-end;
		@include media-breakpoint-down(md) {
			justify-content: flex-start;
		}
		&:hover {
			span {
				text-decoration: underline;
			}
			.sprite {
				transform: scale(.9);
			}
		}
	}
	.link-sub {
		font-size: 12px;
		&:hover {
			text-decoration: underline;
		}
	}
	.pixell {
		> * {
			display: inline-block;
			vertical-align: middle;
		}
	}

	@include media-breakpoint-down(lg) {}
	@include media-breakpoint-down(md) {}
	@include media-breakpoint-down(sm) {
	}
}
