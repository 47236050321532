#slick-home-wp {
	position: relative;

	.slick-slide .product-home-item    { opacity: 0; transform: translateY(-40px) scale(1.05); transition: all 0.3s ease-in-out 0.5s;}
  .slick-slide.animation .product-home-item { opacity: 1; transform: translateY(0) scale(1); }

	#slick-home-arrows {
		position: absolute;
		bottom: 20px;
		left: 350px;
		z-index: 10;
	}
	.slick-dots {
		width: 250px;
		position: absolute;
		bottom: 16px;
		text-align: right;
		left: 80px;
		button {
			background-color: $wh-a;
		}
		.slick-active {
			button {
				background-color: $ye-a;
			}
		}
	}
	@include media-breakpoint-down(lg) {
		#slick-home-arrows {
			left: 250px;
		}
		.slick-dots {
			width: 150px;
		}
	}
	@include media-breakpoint-down(sm) {
		#slick-home-arrows {
			left: 190px;
		}
		.slick-dots {
			display: none !important;
		}
	}
	@include media-breakpoint-down(xs) {
		#slick-home-arrows {
			display: none !important;
		}
		.slick-dots {
	    display: block !important;
	    width: 100%;
	    left: 0;
	    z-index: 100;
	    text-align: center;
		}
	}

}

.slick-home-item {
	display: flex;
	.col-left {
		padding: 100px 75px;
		background-color: $bk-b;
		color: $wh-a;
		flex: 0 0 450px;
		max-width: 450px;
	}
	.col-right {
		display: flex;
		align-items: start;
		justify-content: space-around;
		flex: 0 0 calc(100% - 450px);
		max-width: calc(100% - 450px);
		background-image: url("../images/illustrations/bg-inner.jpg") !important;
		background-position: bottom center;
		background-size: cover;
		padding: 50px 100px;
	}
	@include media-breakpoint-down(xl) {
		.col-right {
			padding: 50px;
		}
	}

	@include media-breakpoint-down(lg) {
		.col-left {
			padding: 50px;
			flex: 0 0 350px;
			max-width: 350px;
		}
		.col-right {
			flex: 0 0 calc(100% - 350px);
			max-width: calc(100% - 350px);
		}
	}
	@include media-breakpoint-down(sm) {
		.col-left {
			flex: 0 0 50%;
			max-width: 50%;
		}
		.col-right {
			flex: 0 0 50%;
			max-width: 50%;
		}
	}

	@include media-breakpoint-down(xs) {
		display: block;
		.col-left,
		.col-right {
			flex: 0 0 100%;
			max-width: 100%;
		}
		.col-right {
			padding: 25px 0 65px;
		}
	}

	@include media-breakpoint-down(md) {
		.col-right {
			.product-home-item:last-child {
				display: none;
			}
		}
	}


	.product-home-item {
		display: block;
		width: 400px;
		color: $wh-a;

		figure {
			text-align: left;
		}
		img {
			width: 275px;
			display: inline-block;
			margin-bottom: 20px;
			transition: all .2s ease-in-out;
		}
		&:hover {
			img {
				transform: translateY(-10px);
			}
		}
		.title {
			font-size: 14px;
			font-weight: 100;
		}
		.info {
			display: block;
			font-size: 11px;
			font-weight: 100;
			margin-top: 5px;
		}
		.price {
			margin-top: 7.5px;
			font-size: 15px;
			color: $ye-a;
		}
		@include media-breakpoint-down(md) {
			text-align: center;
			figure {
				text-align: center;
			}
		}
	}
}

.product-home-item {
	.link {
		display: block;
		background-color: $gy-a;
		padding: 25px;
		img {
			transition: all .2s ease-in-out;
		}
		.header-info {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		.title {
			font-size: 17px;
			font-weight: 100;
		}
		.info {
			font-size: 14px;
			margin-top: 3px;
			font-weight: 100;
		}
		.price {
			color: $ye-a;
			font-size: 14px;
			font-weight: 100;
		}
		&:hover {
			img {}
		}
	}
}

.product-item {
	height: 100%;
	.link {
		display: flex;
		flex-flow: column wrap;
		justify-content: space-between;
		background-color: $gy-a;
		padding: 25px;
		height: 100%;
		transition: all .2s ease-in-out;
		img {
			transition: all .2s ease-in-out;
		}
		.header-info {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		.title {
			font-size: 17px;
			font-weight: 100;
		}
		.info {
			font-size: 14px;
			margin-top: 3px;
			font-weight: 100;
		}

		.price {
			color: $ye-a;
			font-size: 14px;
			font-weight: 100;
			white-space: nowrap;
		}
		&:hover {
			img {
				transform: scale(.95);
			}
			background-color: tint($gy-a, 50%);
		}
		@include media-breakpoint-down(lg) {
			.header-info {
				display: block;
				.price {
					margin-top: 10px;
				}
			}
		}
	}
}

#slick-best-sellers-wp .slick-dots {
	margin-top: 20px;
	.slick-active button {
		background-color: $ye-a;
	}
	@include media-breakpoint-down(xs) {
		display: none !important;
	}
}


.about-wp {
	background-color: $bk-b;
	color: $wh-a;
	padding: 60px;
	position: relative;
	.title {
		> * {
			line-height: 1.3em;
		}
	}

	&:after,
	&:before {
		content: "";
		background-color: $ye-a;
		position: absolute;
	}
	&:before {
		width: 4px;
		height: calc(100% - 10px);
		bottom: -4px;
		right: -4px;
	}
	&:after {
		width: calc(100% - 10px);
		height: 4px;
		bottom: -4px;
		right: -4px;
	}
}
